<template>
	<div class="notice-index-page">
		<van-list
			v-model="loading"
			:finished="finished"
			finished-text="暂无更多记录"
			@load="onLoadListData"
		>
			<van-row class="item" v-for="item in lists" :key="item.id"  @click="goInfo(item.id)">
				<van-col span="24" class="title" v-html="item.name"></van-col>
				<van-col span="24" class="date" v-text="item.date"></van-col>
			</van-row>
		</van-list>
	</div>
</template>

<script>
	export default {
		name: 'notice',
		data() {
			return {
				lists: [],

				finished: false,
				loading: false,

				formItem: {
					page: 1,
					limit: 10,
				},
			}
		},
		
		methods: {
			
			onLoadListData(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'notice/lists',
					data: this.formItem
				}).then ((res) => {

					this.loading = false
					
					if (res.success) {
						if (res.data.length != 0) {
							this.lists = this.lists.concat(res.data)
							
							if (res.data.length < this.formItem.limit) {
								this.finished = true
							} else {
								this.formItem.page ++
							}
						} else {
							this.finished = true
						}
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},

			goInfo(id) {
				this.$router.push('/notice/info?id=' + id) 
			}
			
		}
	}
</script>